import { onMounted } from 'vue'
import { Vue } from 'vue-class-component'
import { pwResetPageURL } from '@/components/Utility/Base'
import CommonFunctions from '@/components/Utility/Common'
import axios from 'axios'
import router from '@/router'
import { LocationQuery, useRoute } from 'vue-router'

export default class resetPassword extends Vue {
  newPassword = ''
  repeatPassword = ''
  queryParameters: LocationQuery = {}
  $Message: any

  created () {
    // console.log('[resetPassword:created()] DONE.')

    const route = useRoute()
    const getUrlQueryParams = async () => {
      // Router is async so we wait for it to be ready.
      await router.isReady()
      // Once its ready we can access the query params.
      this.queryParameters = route.query
      console.log(this.queryParameters)
    }

    onMounted(() => {
      getUrlQueryParams()
      // console.log(route.fullPath)
      // console.log(route.query.code)
    })
  }

  public testPassword (passwordString: string) : boolean {
    return CommonFunctions.strongPasswordTest(passwordString)
  }

  public async updatePassword () : Promise<void> {
    const route = useRoute()

    if (this.queryParameters !== undefined && this.queryParameters !== null && this.queryParameters.code !== undefined && this.queryParameters.code !== null && this.queryParameters.code.length > 1 && this.newPassword !== undefined && this.newPassword !== null && this.newPassword.toString().length >= 8) {
      axios.post(pwResetPageURL, {
        code: this.queryParameters.code.toString().trim(),
        password: this.newPassword.toString(),
        passwordConfirmation: this.repeatPassword.toString()
      })
        .then((response) => {
          console.log('Password reset: ' + response.statusText)
          this.$Message.success({ text: 'Din adgangskode er blevet opdateret, du vil nu blive redirected' })

          setTimeout(() => {
            CommonFunctions.redirectLogin()
          }, 5000)
        })
        .catch((err) => {
          console.log(err.response)
        })
    }
  }
}
