
import { Options, Vue } from 'vue-class-component'
import resetPassword from '@/components/Frontend/ResetPassword/index.vue'
// import resetPassword from '@/components/Frontend/ResetPassword/component'

@Options({
  components: {
    resetPassword
  }
})

export default class ResetPassword extends Vue {}
